import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './routes/routes';
import { Provider } from 'react-redux';
import store from './redux/store';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store} >
    <ToastContainer/>
     <App />
  </Provider>
);
