/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, Navbar, Nav, Offcanvas } from "react-bootstrap";
import Logo from "../../assets/logo.webp";

import MenuIcon from "@iconscout/react-unicons/icons/uil-bars";
import { useDispatch } from "react-redux";

import actions from "../../redux/actions/userAction";

const DashboardHeader = () => {
  const dispatch = useDispatch();
  return (
    <header className="dashboard-header">
      <Navbar expand="lg">
        <div className="container" style={{ display: "block" }}>
          <div className="navigation">
            <div className="left-navigation">
              <div className="logo-box">
                <Link to="/contact-us">
                  <img
                    src={Logo}
                    className="header-logo"
                    alt="Company Logo"
                  />
                </Link>
              </div>
              <Navbar.Toggle aria-controls="responsive-navbar-nav">
                <MenuIcon color="#FFF" size="25" />
              </Navbar.Toggle>
              <Navbar.Offcanvas
                id="responsive-navbar-nav"
                className="mobile-menu"
              >
                <Offcanvas.Header className="mobile-menu-header" closeButton>
                  <img
                    src={Logo}
                    className="header-logo"
                    alt="Company Logo"
                  />
                </Offcanvas.Header>
                <Offcanvas.Body className="mobile-menu-body">
                  <Nav className="navigation-options">
                    <Nav.Link
                      href="/contact-us"
                      className="navigation-item"
                      active={
                        window.location.pathname === "/contact-us"
                          ? true
                          : false
                      }
                    >
                      Contacts Us Leads
                    </Nav.Link>
                    <Nav.Link
                      href="/news-letter"
                      className="navigation-item"
                      active={
                        window.location.pathname === "/news-letter"
                          ? true
                          : false
                      }
                    >
                      News-Letter Leads
                    </Nav.Link>
                    {/* <Nav.Link
                      href="/google-workspace"
                      className="navigation-item"
                      active={
                        window.location.pathname === "/google-workspace"
                          ? true
                          : false
                      }
                    >
                      Google Workspace  Leads
                    </Nav.Link>
                    <Nav.Link
                      href="/career"
                      className="navigation-item"
                      active={
                        window.location.pathname === "/career"
                          ? true
                          : false
                      }
                    >
                      Career Leads
                    </Nav.Link>
                    <Nav.Link
                      href="/blogs"
                      className="navigation-item"
                      active={
                        window.location.pathname === "/blogs"
                          ? true
                          : false
                      }
                    >
                      Blogs
                    </Nav.Link> */}
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </div>
            {/* RIGHT NAVIGATION */}
            <div className="right-navigation">
              <Dropdown className="profile-dropdown">
                <Dropdown.Toggle>
                  <div className="profile-avatar">
                    <div className="admin-details">
                      <p>Administrator</p>
                      <h5>BePositiveU</h5>
                    </div>
                    <img
                      src={require("../../assets/favicon.webp")}
                      alt="Profile"
                    />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="profile-dropdown-menu">
                  <Dropdown.Item href="/" onClick={() => {
                    dispatch(actions.setLoggedIn(false))
                    dispatch(actions.setToken(null))
                    dispatch(actions.setLoggedIn(false))
                    dispatch(actions.setUser(null))
                  }} className="menu-item">
                    Log out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </Navbar>
    </header>
  );
};

export default DashboardHeader;