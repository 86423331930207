
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import userReducer from "./reducer/userReducer";

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  user: userReducer
});

export default rootReducer;